import { reactive } from 'vue'

export default {
  state: reactive({
    grids: []
  }),

  getGrid(id) {
    return this.state.grids[id];
  },

  async initializeState(host) {
      return fetch(`${host}/api/v1/animals`)
        .then(res => res.json())
        .then(data => {
          this.state.grids = data
        });
  }
}
