<template>
  <div class="mt-4" @mousedown="setMouseDown" @mouseup="setMouseUp">
    <table class="border-collapse border border-gray-500 mx-auto select-none">
      <tr
        v-for="(row, ridx) in grid"
        :key="ridx"
      >
        <td
          v-for="(col, cidx) in row"
          :key="cidx"
          :style="{backgroundColor: col}"
          @mousedown="setColor(ridx, cidx, 'click')"
          @mouseover="setColor(ridx, cidx, 'mouseover')"
          :class="['h-' + sizeModifier, 'md:h-' + mdSizeModifier, 'w-' + sizeModifier, 'md:w-' + mdSizeModifier]"
          class="border border-gray-500"
        >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ShadowDrawingCanvas',
  props: {
    refGrid: Array,
    grid: Array,
    mdSizeModifier: {
      type: Number,
      default: 16
    },
    sizeModifier: {
      type: Number,
      default: 8
    }
  },
  data() {
    console.log(this.refGrid);
    return {
      mouseDown: false,
    }
  },
  methods: {
    setMouseDown() {
      this.mouseDown = true
    },
    setMouseUp() {
      this.mouseDown = false
    },
    setColor(ridx, cidx, source) {
      let refColor = this.$props.refGrid[ridx][cidx];
      if (this.mouseDown || source === 'click') {
        this.$props.grid[ridx][cidx] = refColor;
      }
    }
  }
}
</script>

<style>
</style>

