<template>
  <div class="grid grid-cols-1 lg:grid-cols-3">
      <div>
        <div class="mx-auto w-2/3 mt-4">
          <span
            v-for="(isCompleted, canvasType) in completed"
            :key="canvasType"
            class="mx-1 w-4 h-4 inline-block rounded-full border border-gray-200 p-2"
            :class="[isCompleted ? 'bg-green-400' : '']"
          ></span>
          <button
            @click="refresh"
            class="inline-block ml-2 text-3xl"
          >🔄</button>
        </div>
        <div class="mx-auto w-2/3 h-5 bg-gray-200 rounded-xl mb-4">
          <div class="h-5 bg-green-400 rounded-xl" :style="{ width: match() + '%'}"></div>
        </div>
      </div>

      <div class="lg:col-span-2 mx-auto">
        <div class="inline-block mx-2">
          <button
            @click="activeCanvas = NUMBER_CANVAS"
            class="w-16 h-12 text-3xl rounded-md text-center border-b-4 border-green-500 bg-green-400"
            :class="[activeCanvas === 'number' ? 'bg-green-500' : '']"
          >🔢</button>
        </div>

        <div class="inline-block mx-2">
          <button
            @click="activeCanvas = CLICK_CANVAS"
            class="w-16 h-12 text-3xl rounded-md text-center border-b-4 border-green-500 bg-green-400"
            :class="[activeCanvas === 'click' ? 'bg-green-500' : '']"
          >👆</button>
        </div>

        <div class="inline-block mx-2">
          <button
            @click="activeCanvas = DRAW_CANVAS"
            class="w-16 h-12 text-3xl rounded-md text-center border-b-4 border-green-500 bg-green-400"
            :class="[activeCanvas === DRAW_CANVAS ? 'bg-green-500' : '']"
          >🖌</button>
        </div>
      </div>

      <div class="mb-4">
        <DrawingCanvas
          :editable="false"
          :grid="referenceGrid"
          :mdSizeModifier="6"
          :sizeModifier="6"
        ></DrawingCanvas>
      </div>

      <div class="mb-4 lg:col-span-2" v-if="activeCanvas === NUMBER_CANVAS">
        <NumberDrawingCanvas
          :grid="grids[NUMBER_CANVAS]"
          v-model:refGrid="referenceGrid"
        ></NumberDrawingCanvas>
      </div>
      <div class="mb-4 lg:col-span-2" v-else-if="activeCanvas === CLICK_CANVAS">
        <ShadowDrawingCanvas
          :grid="grids[CLICK_CANVAS]"
          v-model:refGrid="referenceGrid"
        ></ShadowDrawingCanvas>
      </div>
      <div class="mb-4 lg:col-span-2" v-else-if="activeCanvas === DRAW_CANVAS">
        <DrawingCanvas
          :grid="grids[DRAW_CANVAS]"
          :startColor="color"
          :pallete="pallete"
        ></DrawingCanvas>
      </div>
  </div>
</template>

<script>
import party from "party-js";
import _ from "lodash";

import NumberDrawingCanvas from '../components/NumberDrawingCanvas.vue'
import ShadowDrawingCanvas from '../components/ShadowDrawingCanvas.vue'
import DrawingCanvas from '../components/DrawingCanvas.vue'
import store from '../store.js'

const NUMBER_CANVAS = 'number'
const CLICK_CANVAS = 'click'
const DRAW_CANVAS = 'canvas'
const BLANK_GRID = [
    ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
    ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
    ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
    ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
    ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
    ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
    ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
    ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
    ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
    ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
] 

export default {
  name: 'Play',
  components: {
    DrawingCanvas,
    ShadowDrawingCanvas,
    NumberDrawingCanvas,
  },
  data() {
    let grid = store.getGrid(this.$route.params.id)

    return {
      NUMBER_CANVAS,
      CLICK_CANVAS,
      DRAW_CANVAS,
      activeCanvas: NUMBER_CANVAS,
      color: grid.startColor,
      pallete: grid.pallete,
      referenceGrid: grid.grid,
      grids: {
        [NUMBER_CANVAS]: _.cloneDeep(BLANK_GRID),
        [CLICK_CANVAS]: _.cloneDeep(BLANK_GRID),
        [DRAW_CANVAS]: _.cloneDeep(BLANK_GRID),
      },
      completed: {
        [NUMBER_CANVAS]: false,
        [CLICK_CANVAS]: false,
        [DRAW_CANVAS]: false,
      }
    }
  },
  methods: {
    notifyColor(color) {
      this.color = color;
    },
    doConfetti() {
      let el = document.getElementsByTagName('table')[1];
      party.confetti(el, {
        count: party.variation.range(200, 300)
      })
    },
    match() {
      let userGrid = this.grids[this.activeCanvas].flat()
      let matches = this.referenceGrid.flat().map((val, idx) => {
        return val === userGrid[idx]
      })
      let countMatches =  matches.reduce((acc, val) => {
        return val ? 1 + acc : 0 + acc
      }, 0);

      let percent = Math.round(countMatches / matches.length * 100);

      if (percent >= 100 && !this.completed[this.activeCanvas]) {
        this.completed[this.activeCanvas] = true
        this.doConfetti()
      }

      return percent
    },
    refresh() {
      for (let key in this.grids) {
        this.completed[key] = false;
        this.grids[key] = _.cloneDeep(BLANK_GRID)
      }
    }
  },
}
</script>

<style>
</style>
