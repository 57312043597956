<template>
  <div class="mt-4" @mousedown="setMouseDown" @mouseup="setMouseUp">
    <ColorPicker @notifyColor="notifyColor" :pallete="pallete" :color="color"></ColorPicker>
    <table class="border-collapse border border-gray-500 mx-auto select-none">
      <tr
        v-for="(row, ridx) in grid"
        :key="ridx"
      >
        <td
          v-for="(col, cidx) in row"
          :key="cidx"
          :style="{backgroundColor: col}"
          @mousedown="setColor(ridx, cidx, 'click')"
          @mouseover="setColor(ridx, cidx, 'mouseover')"
          :class="['h-' + sizeModifier, 'md:h-' + mdSizeModifier, 'w-' + sizeModifier, 'md:w-' + mdSizeModifier]"
          class="border border-gray-500"
        >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import ColorPicker from '../components/ColorPicker.vue'

export default {
  name: 'DrawingCanvas',
  components: {
    ColorPicker,
  },
  props: {
    grid: Array,
    startColor: String,
    pallete: Array,
    editable: {
      type: Boolean,
      default: true
    },
    mdSizeModifier: {
      type: Number,
      default: 16
    },
    sizeModifier: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {
      mouseDown: false,
      color: this.$props.startColor,
    }
  },
  methods: {
    notifyColor(color) {
      this.color = color;
    },
    setMouseDown() {
      this.mouseDown = true
    },
    setMouseUp() {
      this.mouseDown = false
    },
    setColor(ridx, cidx, source) {
      if (this.$props.editable && (this.mouseDown || source === 'click')) {
        this.$props.grid[ridx][cidx] = this.color
      }
    }
  }
}
</script>

<style>
</style>
