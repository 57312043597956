import { createWebHistory, createRouter } from 'vue-router'
import List from './pages/List.vue'
import Play from './pages/Play.vue'
import Design from './pages/Design.vue'

const routes = [
  {
    path: '/',
    name: 'List',
    component: List,
  },
  {
    path: '/play/:id',
    name: 'Play',
    component: Play,
  },
  {
    path: '/design',
    name: 'Design',
    component: Design,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
