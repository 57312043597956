<template>
  <div class="mt-4" @mousedown="setMouseDown" @mouseup="setMouseUp">
    <table class="border-collapse border border-gray-500 mx-auto select-none">
      <tr
        v-for="(row, ridx) in grid"
        :key="ridx"
      >
        <td
          v-for="(col, cidx) in row"
          :key="cidx"
          :style="{backgroundColor: col}"
          @mousedown="setColor(ridx, cidx, 'click')"
          @mouseover="setColor(ridx, cidx, 'mouseover')"
          :class="['h-' + sizeModifier, 'md:h-' + mdSizeModifier, 'w-' + sizeModifier, 'md:w-' + mdSizeModifier]"
          class="border border-gray-600 font-2xl font-bold text-center"
        >
            {{numberMap[refGrid[ridx][cidx]]}}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: 'NumberDrawingCanvas',
  props: {
    refGrid: Array,
    grid: Array,
    mdSizeModifier: {
      type: Number,
      default: 16
    },
    sizeModifier: {
      type: Number,
      default: 8
    }
  },
  data() {
    let assignedNumber = 1
    let numberMap = {} 
    this.refGrid.forEach(rows => {
        rows.forEach(color => {
            if (numberMap[color] === undefined) {
                numberMap[color] = assignedNumber++
            }
        })
    })
    let curNumber = _.flatten(this.grid).reduce((max, cell) => {
      if (numberMap[cell] && numberMap[cell] >= max) {
        return numberMap[cell] + 1;
      }
      return max;
    }, 1)

    return {
      numberMap,
      curNumber,
      mouseDown: false,
    }
  },
  watch: {
    grid(val) {
      let nonWhite = _.flatten(val).filter(cell => cell !== 'white');
      if (nonWhite.length === 0) {
        this.curNumber = 1;
      }
    }
  },
  methods: {
    setMouseDown() {
      this.mouseDown = true
    },
    setMouseUp() {
      this.mouseDown = false
    },
    setColor(ridx, cidx, source) {
      let refColor = this.$props.refGrid[ridx][cidx]
      let canColorNow = this.numberMap[refColor] === this.curNumber

      if (canColorNow && (this.mouseDown || source === 'click')) {
        this.refGrid.forEach((row, ridx) => {
            row.forEach((color, cidx) => {
                if (color === refColor) {
                  this.$props.grid[ridx][cidx] = refColor;
                }
            })
        })
        this.curNumber += 1
      }
    }
  }
}
</script>

<style>
</style>
