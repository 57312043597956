<template>
  <div class="w-80 relative">
    <div class="mx-auto">
      <div
        class="w-7 h-7 inline-block m-0.5 rounded-full border border-gray-600 inline-block"
        :style="{backgroundColor: selectedColor}"
        @click="expand"
      ></div>
    </div>
    <div class="border rounded-lg border-gray-200 p-2 shadow-2xl absolute bg-white" v-show="expanded">
      <div
        v-for="(color, idx) in colors"
        class="w-6 h-6 inline-block m-0.5 rounded-full"
        :key="idx"
        :style="{backgroundColor: color}"
        @click="onClick(color)"
        :class="[color === selectedColor ? 'border-gray-700 border-4' : 'border-gray-600 border']"
      ></div>
    </div>
  </div>
</template>

<script>
const COLOR_OPTIONS = ['aliceblue','antiquewhite','aqua','aquamarine','azure','beige','bisque','black','blanchedalmond','blue','blueviolet','brown','burlywood','cadetblue','chartreuse','chocolate','coral','cornflowerblue','cornsilk','crimson','cyan','darkblue','darkcyan','darkgoldenrod','darkgray','darkgreen','darkgrey','darkkhaki','darkmagenta','darkolivegreen','darkorange','darkorchid','darkred','darksalmon','darkseagreen','darkslateblue','darkslategray','darkslategrey','darkturquoise','darkviolet','deeppink','deepskyblue','dimgray','dimgrey','dodgerblue','firebrick','floralwhite','forestgreen','fuchsia','gainsboro','ghostwhite','gold','goldenrod','gray','green','greenyellow','grey','honeydew','hotpink','indianred','indigo','ivory','khaki','lavender','lavenderblush','lawngreen','lemonchiffon','lightblue','lightcoral','lightcyan','lightgoldenrodyellow','lightgreen','lightgrey','lightpink','lightsalmon','lightseagreen','lightskyblue','lightslategray','lightslategrey','lightsteelblue','lightyellow','lime','limegreen','linen','magenta','maroon','mediumaquamarine','mediumblue','mediumorchid','mediumpurple','mediumseagreen','mediumslateblue','mediumspringgreen','mediumturquoise','mediumvioletred','midnightblue','mintcream','mistyrose','moccasin','navajowhite','navy','oldlace','olive','olivedrab','orange','orangered','orchid','palegoldenrod','palegreen','paleturquoise','palevioletred','papayawhip','peachpuff','peru','pink','plum','powderblue','purple','red','rosybrown','royalblue','saddlebrown','salmon','sandybrown','seagreen','seashell','sienna','silver','skyblue','slateblue','slategray','slategrey','snow','springgreen','steelblue','tan','teal','thistle','tomato','turquoise','violet','wheat','white','whitesmoke','yellow','yellowgreen']

export default {
  name: 'ColorSwatch',
  emits: ['colorSelect'],
  mounted() {
    this.$emit('colorSelect', this.selectedColor)
  },
  data() {
    return {
      colors: COLOR_OPTIONS,
      selectedColor: COLOR_OPTIONS[0],
      expanded: false,
    }
  },
  methods: {
    onClick(color) {
      this.selectedColor = color
      this.expanded = false
      this.$emit('colorSelect', color)
    },
    expand() {
      this.expanded = !this.expanded
    }
  },
}
</script>

<style>
</style>
