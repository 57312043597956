<template>
  <div class="grid grid-cols-1">

      <div class="mx-auto">
        <div class="mx-auto">
          <ColorSwatch @color-select="onColorSelect"></ColorSwatch>
          <button @click="addPalleteColor" class="inline-block border border-color-500 px-2 py-1 m-1 block">Add</button>
          <button @click="removePalleteColor" class="inline-block border border-color-500 px-2 py-1 m-1 block">Remove</button>
          <div>
            <label for="animalName">Animal Name:</label>
            <input type="text" id="animalName" v-model="animalName" class="block border border-color-500 inline ml-2" />
            <input type="number" id="dimenesions" v-model="dimenesions" class="block border border-color-500 inline ml-2" />
          </div>
        </div>

        <DrawingCanvas
          :startColor="color"
          :pallete="pallete"
          v-model:grid="grid"
        ></DrawingCanvas>
      </div>

      <div class="mx-auto p-4">
        <code class="whitespace-normal">
        {{
          JSON.stringify({
            "animal": animalName,
            "startColor": pallete[0],
            "pallete": pallete,
            "grid": grid,
          })
        }}
        </code>
      </div>

  </div>
</template>

<script>
import DrawingCanvas from '../components/DrawingCanvas.vue'
import ColorSwatch from '../components/ColorSwatch.vue'

export default {
  name: 'Design',
  components: {
    DrawingCanvas,
    ColorSwatch,
  },
  data() {
    let grid = [
        ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
      ]
    return {
      palleteColor: '',
      animalName: '',
      color: '',
      pallete: [],
      grid: grid,
      dimensions: grid.length,
    }
  },
  methods: {
    addPalleteColor() {
      if (this.palleteColor) {
        this.pallete.push(this.palleteColor)
      }
    },
    removePalleteColor() {
      this.pallete.pop()
    },
    onColorSelect(color) {
      this.palleteColor = color
    }
  },
}
</script>

<style>
</style>
