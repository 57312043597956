import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import './index.css'
import store from './store.js'

async function app() {
  let host = process.env.NODE_ENV === "production" ? "https://safari-sketch.com" : "http://localhost:8021"
  await store.initializeState(host);
  let app = createApp(App).use(router);
  app.config.globalProperties.$host = host;
  app.mount('#app')
}

app();
