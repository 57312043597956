<template>
  <div class="flex m-4 shadow-md">
    <div
      class="flex-1 text-center text-2xl align-middle p-4 cursor-pointer"
      v-for="c in pallete"
      :key="c"
      :style="{ backgroundColor: c }"
      @click="setColor(c)"
    >
      <span v-if="c === activeColor">🖌️</span>
    </div>
  </div>
</template>

<script>
export default {
  name:'ColorPicker',
  props: ['pallete', 'color'],
  emits: ['notifyColor'],
  data() {
    return {
      activeColor: this.$props.color,
    }
  },
  created() {
    this.$emit('notifyColor', this.activeColor)
  },
  methods: {
    setColor(color) {
      this.activeColor = color;
      this.$emit('notifyColor', color);
    }
  }
}
</script>

<style>
</style>
