<template>
  <div>
    <router-link to="/" v-if="$route.name != 'List'" class="inline-block ml-5 mt-3 text-3xl">⬅️ </router-link>
    <router-view class="mt-3"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
</style>
