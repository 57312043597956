<template>
    <div class="container mx-auto">
      <div class="mx-auto my-20">
        <h1 class="font-sans text-6xl font-medium text-center mb-4">Safari Sketch</h1>
        <p class="font-sans text-lg text-center">Get ready to embark on a drawing safari!</p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div v-for="(grid, idx) in grids" :key="idx" @click="gotoPlay(idx)" class="mx-2">
              <Card :title="grid.animal">
                  <DrawingCanvas
                    :color="grid.startColor"
                    :grid="grid.grid"
                    :mdSizeModifier="4"
                    :sizeModifier="4"
                    :editable="false"
                  ></DrawingCanvas>
              </Card>
          </div>
      </div>
    </div>
</template>

<script>
import Card from '../components/Card.vue'
import DrawingCanvas from '../components/DrawingCanvas.vue'
import store from '../store.js'

export default {
  name: 'List',
  components: {
    DrawingCanvas,
    Card,
  },
  data() {
    return store.state
  },
  methods: {
    gotoPlay(idx) {
      this.$router.push('/play/' + idx)
    }
  }
}
</script>

<style>
</style>

