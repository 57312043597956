<template>
    <div class="bg-white shadow-xl border border-gray-200 rounded-lg max-w-sm mb-5 mx-auto cursor-pointer">
        <slot></slot>
        <div class="mx-auto my-3 px-5 max-w-sm">
          <span class="text-center text-xl px-1 text-gray-700">{{title}}</span>
          <span class="text-center bg-green-200 border rounded-xl px-1 text-green-700">Easy</span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: String,
  },
  data() {
    return {
    }
  },
}
</script>

<style>
</style>

